import { Katilas } from "./Katilas";

export const MOCK_KATILAI_EN_2 = [
    new Katilas({
        id: 1,
        name: "Pellet Uni",
        type: "Pellet fuel",
        imageUrl: "assets/pellet_uni.png",
        pjuvis: "assets/pellet_uni_pjuvis.png",
        feature: "Top of the line",
        price: 3400,
        pricing: [
            [12, 3400, 0, [0, 30, 80, 110]],
            [16, 3530, 0, [0, 30, 80, 110]],
            [20, 3650, 120, [0, 30, 80, 110]],
            [26, 4070, 160, [0, 30, 80, 110]],
            [36, 4330, 160, [0, 30, 80, 110]],
        ],
        minPower: 12,
        maxPower: 36,
        power: [12, 16, 20, 26, 36],
        minArea: 120,
        maxArea: 360,
        efficiencyClass: "A++",
        fuelTypes: ["Pellet", "Wood", "Briquettes", "Coal"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Heated area", "till m\u00b2", 120, 160, 200, 260, 360],
            ["Combustion chamber depth", "mm", 455, 455, 455, 505, 505],
            ["Combustion chamber load", "l/dm\u00b3", 55, 55, 65, 84, 93],
            ["Fuel chamber capacity", "l/dm\u00b3", 230, 230, 250, 270, 270],
            ["Rotary pellet burner", "kW", "4-16", "4-16", "5-20", "6-26", "8-36"],
            ["Heat exchanger area", "m\u00b2", 2.2, 2.6, 2.9, 3.4, 3.7],
            ["Combustion chamber opening size", "cm", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Number of horizontal heat exchangers", "units", 3, 4, 4, 4, 4],
            ["Volume of water in the boiler", "l", 59, 63, 68, 80, 85],
            ["Weight", "kg", 260, 280, 310, 360, 380],
            ["Required draft in the chimney", "Pa", 12, 13, 14, 15, 15],
        ],
        additionalData: [
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "90%"],
            ["Chimney inner diameter", "150 mm"],
            ["Hydraulic connections size", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Highest operating pressure", "1.5 bar"],
        ],
        selections: [
            ["Kuro talpa", "230 l", "260 l", "320 l", "350 l"],
            [
                ["Degiklis"],
                ["12", "4-16 kW"],
                ["16", "4-16 kW"],
                ["20", "4-16 kW", "5-20 kW"],
                ["26", "5-20 kW", "6-26 kW"],
                ["36", "6-26 kW", "8-36 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1150, 1260, 1260, 1260, 1260],
            ["B", "mm", 440, 440, 490, 540, 590],
            ["C", "mm", 640, 640, 640, 690, 690],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Power output", "10-36 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pu1en", "pu2en", "pu3en", "pu4en", "pu5en", "pu6en", "pu7en"],
        iconLocations: [[245, 150], [208, 350], [320, 380], [245, 150], [242, 330], [315, 70], [355, 170]],
        description: "Pellet fuel boiler of the highest technical level, with a four-pass heat exchanger and a ceramic deflector. Cast iron grates and ceramic deflector ensure smooth combustion. This makes it possible to achieve a boiler efficiency of more than 90%. The fully automatic rotary burner is designed to burn pellets of lower quality and higher ash content. The rotating combustion chamber helps to burn the fuel completely and prevents the formation of slag. The controller runs boiler room cleaning and all boiler burning processes.",
        burnerDescription: "Fully automatic pellet burner, which was designed to burn good to medium quality pellets. The burner has an automatic cleaning mechanism - a rotating combustion and air supply chamber, which helps the burner to clean itself from ash and slag.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
        dimensionsImage: "../assets/pelletUniDimensions.svg",
        certificatePdf: "../assets/Bandymu sertifikatas Pellet Uni.pdf",
        instructionsPdf: "../assets/pellet_uni_instruction.pdf",
        techSpecPdf: "../assets/pellet_uni_en.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        connectionSchemePdf: "../assets/pelletUniPajungimasEN.pdf",
    }),
    new Katilas({
        id: 2,
        name: "Bio Kompakt",
        type: "Pellet fuel",
        imageUrl: "assets/bio_kompakt.png",
        pjuvis: "assets/bio_kompakt_pjuvis.png",
        feature: "New",
        price: 2900,
        pricing:[
            [10, 2900, 0, [0]],
            [15, 3000, 0, [0]],
            [20, 3100, 100, [0]],
        ],
        minPower: 10,
        maxPower: 20,
        power: [10, 15, 20],
        minArea: 100,
        maxArea: 200,
        efficiencyClass: "A++",
        fuelTypes: ["Pellet"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Heated area", "till m\u00b2", 100, 150, 200],
            ["Fuel chamber capacity", "l/dm\u00b3", 120, 160, 190],
            ["Rotary pellet burner", "kW", "4-16", "4-16", "5-20"],
            ["Heat exchanger area", "m\u00b2", 1.7, 2.0 , 2.4],
            ["Number of horizontal heat exchangers", "units", 2, 2, 2],
            ["Heating efficiency", "%", "89", "90", "91"],
            ["Volume of water in the boiler", "l", 44, 50, 58],
            ["Weight", "kg", 155, 175, 195],
        ],
        additionalData: [
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Chimney inner diameter", "130 mm"],
            ["Hydraulic connections size", "G 1 \u00b9\u{141F}\u2074 inches"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "Be talpos"],
            [
                ["Degiklis"],
                ["10", "4-16 kW"],
                ["15", "4-16 kW"],
                ["20", "4-16 kW", "5-20 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 470, 520, 570],
            ["B", "mm", 645, 695, 745],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Power output", "10-20 kW"],
        ],
        icons: ["bk1en", "bk2en", "bk3en", "bk4en", "bk5en", "bk6en", "bk7en"],
        iconLocations: [[290, 386], [230, 360], [375, 380], [298, 325], [298, 325], [0, 0], [414, 345]],
        description: "Compact pellet fuel boiler, with ceramic combustion chamber, ceramic deflector and large heat exchanger. Long-lasting high-temperature ceramics support combustion and ensure a smooth combustion process. This makes it possible to achieve a boiler efficiency of more than 90%. The fully automatic rotary burner is designed to burn pellets of lower quality and higher ash content. The rotating combustion chamber helps to burn the fuel completely and prevents the formation of slag. The controller controls all boiler room cleaning and boiler burning processes.",
        burnerDescription: "Fully automatic pellet burner, which was designed to burn good to medium quality pellets. The burner has an automatic cleaning mechanism - a rotating combustion and air supply chamber, which helps the burner to clean itself from ash and slag.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
        dimensionsImage: "../assets/bioKompaktDimensions.svg",
        certificatePdf: "../assets/Bio Kompakt 10-20_Bandymu sertifikatas Nr. 02.24-ST [LT].pdf",
        instructionsPdf: "../assets/bio_kompakt_instruction.pdf",
        techSpecPdf: "../assets/bio_kompakt_en.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        connectionSchemePdf: "../assets/bioKompaktPajungimasEN.pdf",
    }),
    new Katilas({
        id: 3,
        name: "Ekon Max",
        type: "Solid fuel",
        imageUrl: "assets/ekon_max.png",
        pjuvis: "assets/ekon_max_pjuvis.png",
        feature: "Efficient",
        price: 1950,
        pricing:[
            [16, 1950, 0, [0]],
            [20, 2150, 0, [0]],
            [25, 2350, 0, [0]],
            [30, 2550, 0, [0]],
            [40, 2850, 0, [0]],
        ],
        minPower: 16,
        maxPower: 40,
        power: [16, 20, 25, 30, 40],
        minArea: 160,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Wood", "Briquettes", "Sawdust", "Coal"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Heated area", "till m\u00b2", 160, 200, 250, 300, 400],
            ["Combustion chamber load", "l/dm\u00b3", 105, 125, 140, 160, 192],
            ["Combustion chamber depth", "mm", 500, 500, 500, 500, 550],
            ["Combustion chamber opening size", "cm", "29x23", "34x23", "39x23", "44x23", "44x23"],
            ["Heat exchanger area", "m\u00b2", 3.0, 3.4, 3.8, 4.2, 4.8],
            ["Number of horizontal heat exchangers", "units", 3, 3,3,3,3],
            ["Volume of water in the boiler", "l", 66,72,78,85,115],
            ["Weight", "kg", 230,260,290,320,350],
            ["Chimney inner diameter", "mm", "150", "185", "185", "185", "185"],
        ],
        additionalData: [
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "85%"],
            ["Hydraulic connections size", "G 1 \u00b9\u{141F}\u00b2 colių"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", ">20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1200, 1200, 1200, 1200, 1300],
            ["B", "mm", 420, 470, 520, 570, 570],
            ["C", "mm", 810, 810, 810, 810, 860],
        ],
        icons: ["em1en", "em2en", "em3en", "em4en", "em5en", "em6en", "em7en", "em8en"],
        iconLocations: [[224, 380], [218, 328], [254, 320], [302, 154], [360, 183], [308, 254], [408, 435], [430, 240]],
        description: "The long-standing design with a double combustion chamber and three horizontal heat exchangers ensures the best performance of the boiler, easy maintenance and a long service life. The boiler is very suitable for burning wood and coal. The largest area of the heat exchanger, the fuel load capacity ensures a high efficiency factor and a very long burning time. This boiler has an excellent value for money.",
        dimensionsImage: "../assets/ekonMaxDimensions.svg",
        techSpecPdf: "../assets/ekon_max_en.pdf",
        connectionSchemePdf: "../assets/ekonMaxPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON MAX.pdf",
        instructionsPdf: "../assets/ekon_max_instruction.pdf",
    }),
    new Katilas({
        id: 4,
        name: "Ekon",
        type: "Solid fuel",
        imageUrl: "assets/ekon.png",
        pjuvis: "assets/ekon_pjuvis.png",
        feature: "Economical",
        price: 1300,
        pricing:[
            [10, 1300, 0, [0]],
            [13, 1500, 0, [0]],
            [16, 1650, 0, [0]],
            [20, 1850, 0, [0]],
        ],
        minPower: 10,
        maxPower: 20,
        power: [10, 13, 16, 20],
        minArea: 100,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Wood", "Briquettes", "Sawdust", "Coal"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Heated area", "till m\u00b2", 100, 130, 160, 200],
            ["Combustion chamber load", "l/dm\u00b3", 65, 85, 100, 115],
            ["Combustion chamber depth", "mm", 400, 400, 400, 400],
            ["Combustion chamber opening size", "cm", "29x23", "29x23", "34x23", "39x23"],
            ["Heat exchanger area", "m\u00b2", 1.9, 2.2, 2.6, 2.8],
            ["Number of horizontal heat exchangers", "units", 2,2,2,2],
            ["Volume of water in the boiler", "l", 41, 52, 56, 68],
            ["Weight", "kg", 160, 190, 210, 250],
            ["Chimney inner diameter", "mm", "150", "150", "150", "185"],
        ],
        additionalData: [
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "83%"],
            ["Hydraulic connections size", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 910, 1100, 1100, 1150],
            ["B", "mm", 420, 420, 470, 520],
        ],
        icons: ["e1en", "e2en", "e3en", "e4en", "e5en", "e6en", "e7en", "e8en"],
        iconLocations: [[224, 360], [230, 316], [258, 318], [304, 126], [360, 150], [320, 210], [405, 435], [420, 220]],
        description: "The long-standing design with double combustion chamber and horizontal heat exchangers ensures the best performance of the boiler, easy maintenance and long service life. The boiler is very suitable for burning wood, briquettes and coal. The large area of the heat exchanger, the fuel load capacity ensures a high efficiency factor, economical burning and a very long burning time. This boiler has an excellent value for money.",
        dimensionsImage: "../assets/ekonDimensions.svg",
        techSpecPdf: "../assets/ekon_en.pdf",
        connectionSchemePdf: "../assets/ekonPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON.pdf",
        instructionsPdf: "../assets/ekon_instruction.pdf",
    }),
    new Katilas({
        id: 5,
        name: "Klasika",
        type: "Solid fuel",
        imageUrl: "assets/klasika.png",
        pjuvis: "assets/klasika_pjuvis.png",
        feature: "Easy to use",
        price: 1050,
        pricing:[
            [8, 1050, 0, [0]],
            [10, 1150, 0, [0]],
            [13, 1300, 0, [0]],
            [16, 1500, 0, [0]],
            [20, 1650, 0, [0]],
            [25, 1800, 0, [0]],
            [30, 1950, 0, [0]],
        ],
        minPower: 8,
        maxPower: 30,
        power: [8, 10, 13, 16, 20, 25, 30],
        minArea: 80,
        maxArea: 300,
        efficiencyClass: "A+",
        fuelTypes: ["Wood", "Briquettes", "Coal"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Heated area", "till m\u00b2", 80, 100, 130, 160, 200, 250, 300],
            ["Combustion chamber load", "l/dm\u00b3", 60, 72, 90, 105, 125, 140, 160],
            ["Combustion chamber depth", "mm", 400, 400, 500, 500, 500, 500, 500],
            ["Combustion chamber opening size", "cm", "24x23", "29x23", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Heat exchanger area", "m\u00b2", 1.3, 1.5, 1.8, 2.3, 2.5, 2.8, 3.1],
            ["Number of horizontal heat exchangers", "units", 2,2,2,3,3,3,3],
            ["Volume of water in the boiler", "l", 38,42,50,59,63,58,72],
            ["Weight", "kg", 135,150,170,200,225,250,275],
            ["Chimney inner diameter", "mm", "150", "150", "150", "150", "185", "185", "185"],
            ["Hydraulic connections size", "colių", "G 1 \u00b9\u{141F}\u2074", "G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2"]
        ],
        additionalData: [
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "83%"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000, 1000, 1000, 1200, 1200, 1200, 1200],
            ["B", "mm", 370, 420, 420, 420, 470, 520, 570],
            ["C", "mm", 570, 570, 670, 670, 670, 670, 670],
        ],
        icons: ["kl1en", "kl2en", "kl3en", "kl4en", "kl5en"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "The classic boiler design, with a large fuel load capacity and a large heat exchanger, is one of the oldest in our range. This ensures a very long burning time. The boiler is very suitable for burning wood, moist solid fuel. It is an inexpensive, reliable, simple and time-tested boiler.",
        dimensionsImage: "../assets/klasikaDimensions.svg",
        techSpecPdf: "../assets/klasika_en.pdf",
        connectionSchemePdf: "../assets/klasikaPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KLASIKA.pdf",
        instructionsPdf: "../assets/klasika_instruction.pdf",
    }),
    new Katilas({
        id: 6,
        name: "Kompakt",
        type: "Solid fuel",
        imageUrl: "assets/kompakt.png",
        pjuvis: "assets/kompakt_pjuvis.png",
        feature: "Inexpensive",
        price: 1100,
        pricing:[
            [12, 1100, 0, [0]],
            [16, 1250, 0, [0]],
            [20, 1400, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Wood", "Briquettes", "Coal"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Heated area", "till m\u00b2", 120, 160, 200],
            ["Combustion chamber load", "l/dm\u00b3", 72, 90, 105],
            ["Combustion chamber depth", "mm", 400, 500, 500],
            ["Combustion chamber opening size", "cm", "29x23", "29x23", "29x23"],
            ["Number of horizontal heat exchangers", "units", 2,2,3],
            ["Volume of water in the boiler", "l", 42, 50, 59],
            ["Weight", "kg", 150, 170, 200],
            ["Hydraulic connections size", "inches", "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u00b2"],
        ],
        additionalData: [
            ["Chimney inner diameter", "150 mm"],
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "83%"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", ">15 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000,1000,1200],
            ["B", "mm", 570,670,670],
        ],
        icons: ["ko1en", "ko2en", "ko3en", "ko4en", "ko5en"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Solid fuel boiler of long-lasting and reliable construction, with a large heat exchanger and a large fuel load. It is characterized by easy maintenance and operation, long burning time. Boiler cleaning is facilitated by a separate door. The compact design allows the boiler to be installed in small rooms. The smoke extraction valve is designed for easier ignition and reduces the possibility of smoke entering the room when loading fuel. The boiler is equipped with a double thrust control valve, strong, easy-to-open doors, and durable cast iron grates that ensure better fuel combustion.",
        dimensionsImage: "../assets/kompaktDimensions.svg",
        techSpecPdf: "../assets/kompakt_en.pdf",
        connectionSchemePdf: "../assets/kompaktPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KOMPAKT.pdf",
        instructionsPdf: "../assets/kompakt_instruction.pdf",
    }),
    new Katilas({
        id: 7,
        name: "Ignis",
        type: "Solid fuel",
        imageUrl: "assets/ignis.png",
        pjuvis: "assets/ignis_pjuvis.png",
        feature: "Practical",
        price: 1050,
        pricing:[
            [10, 1050, 0, [0]],
            [15, 1350, 0, [0]],
        ],
        minPower: 10,
        maxPower: 15,
        power: ["M 10", "D 15"],
        minArea: 100,
        maxArea: 150,
        efficiencyClass: "A+",
        fuelTypes: ["Wood", "Briquettes", "Coal"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Heated area", "till m\u00b2", 100, 150],
            ["Combustion chamber load", "l/dm\u00b3", 70, 90],
            ["Combustion chamber depth", "mm", 300, 400],
            ["Combustion chamber opening size", "cm", "34x26", "34x26"],
            ["Heat exchanger area", "m\u00b2", 1.2, 1.8],
            ["Number of vertical heat exchangers", "units", 1, 2],
            ["Volume of water in the boiler", "l", 32, 42],
            ["Weight", "kg", 130, 190],
            ["Heating efficiency", "%", 76, 78],
            ["Chimney inner diameter", "mm", "130", "150"]
        ],
        additionalData: [
            ["Hydraulic connections size", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", "15-20 Pa"], 
        ],
        selections: [
            [""],
            [""],
        ],
        icons: ["i1en", "i2en", "i3en", "i4en", "i5en"],
        iconLocations: [[233, 190], [350, 220], [434, 260], [290, 240], [430, 204]],
        description: "Boiler-stove, traditional, time tested product. The boiler has a very large wood load capacity and a large heat exchanger. The design ensures high efficiency of the boiler and long burning time. The high-quality, certified 5-millimeter-thick steel sheet ensures a long service life. Impeccable quality and reliable construction ensure the product with a 4-year warranty. The boiler can be used in summer and winter modes, it is possible to install an automatic draft regulator. The boiler is equipped with a high-quality cast-iron hob, a door that can be changed in direction, and a convenient mechanical draft regulator.",
        dimensionsImage: "../assets/ignisDimensions.svg",
        techSpecPdf: "../assets/ignis_en.pdf",
        connectionSchemePdf: "../assets/ignisPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos IGNIS.pdf",
        instructionsPdf: "../assets/ignis_instruction.pdf",
    }),
    new Katilas({
        id: 8,
        name: "Agro Uni",
        type: "Agricultural waste",
        imageUrl: "assets/agro_uni.png",
        pjuvis: "assets/agro_uni_pjuvis.png",
        feature: "Ecological",
        price: 2750,
        pricing:[
            [15, 2750, 0, [0,250]],
            [20, 3100, 0, [0,250]],
            [30, 3500, 0, [0,350]],
            [40, 4000, 0, [0,350]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15, 20, 30, 40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Grains", "Siftings", "Pellet", "Chips", "Coal 0-50", "Wood"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Heated area", "till m\u00b2", 150, 200, 300, 400],
            ["Combustion chamber depth", "mm", 450, 450, 500, 600],
            ["Combustion chamber load", "l/dm\u00b3", 35, 40, 65, 104],
            ["„Zenono“ burner", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Heat exchanger area", "m\u00b2", 1.9, 2.5, 3.3, 4.2],
            ["Combustion chamber opening size", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Number of horizontal heat exchangers", "units", 3, 4, 4, 4],
            ["Volume of water in the boiler", "l", 48, 62, 69, 100],
            ["Weight", "kg", 180, 230, 270, 310],
            ["Chimney inner diameter", "mm", "150", "150", "150", "185"],
        ],
        additionalData: [
            ["Fuel chamber capacity with a mixer", "400/600 l/dm\u00b3"],
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "90%"],
            ["Hydraulic connections size", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Power output", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["au1en", "au2en", "au3en", "au4en", "au5en", "au6en", "au7en"],
        iconLocations: [[240, 350], [240, 350], [410, 316], [235, 320], [240, 190], [240, 190], [320, 210]],
        description: "A particularly versatile boiler for agricultural waste, which can automatically burn various grain crops, agricultural waste, low-quality pellets, chips, sawdust and coal, wood and other solid fuel, which is loaded through the middle door. A special mechanism for mixing the fuel tank, a self-cleaning burner, a large ash box and other solutions ensure the smooth burning of various types of fuel and the operation of the boiler. A large horizontal four-pass heat exchanger ensures convenient cleaning and a high efficiency factor of the boiler. The boiler is equipped with a „Zenono“ burner. We do not recommend equipping the boiler with a stainless steel hood.",
        burnerDescription: "Specialized burner capable of burning various grain crops, agricultural waste, low-quality pellets and various size coal in automatic mode. The burner has a stainless steel cleaning mechanism that perfectly removes slag formed during combustion.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroUniDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO UNI.pdf",
        instructionsPdf: "../assets/agro_uni_instruction.pdf",
        techSpecPdf: "../assets/agro_uni_en.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroUniPajungimasEN.pdf",
    }),
    new Katilas({
        id: 9,
        name: "Universa",
        type: ["Pellet fuel", "Agricultural waste"],
        imageUrl: "assets/universa.png",
        pjuvis: "assets/universa_pjuvis.png",
        feature: "Popular",
        price: 2550,
        pricing:[
            [15, 2550, 0, [0, 50]],
            [20, 2900, 0, [0, 50]],
            [30, 3300, 0, [0, 50]],
            [40, 3850, 0, [0, 50]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15,20,30,40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Pellet", "Grains", "Peat", "Straw pellet", "Sunflower pellet", "Coal 0-50"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Heated area", "till m\u00b2", 150,200,300,400],
            ["Combustion chamber depth", "mm", 450,450,500,600],
            ["Combustion chamber load", "l/dm\u00b3", 35,40,65,104],
            ["„Zenono“ burner", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Heat exchanger area", "m\u00b2", 1.9,2.5,3.3,4.2],
            ["Combustion chamber opening size", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Number of horizontal heat exchangers", "units", 3, 4, 4,4],
            ["Volume of water in the boiler", "l", 48,62,69,100],
            ["Weight", "kg", 180,230,270,320],
            ["Chimney inner diameter", "mm", "150","150","150","185"],
        ],
        additionalData: [
            ["Fuel chamber capacity", "200/300 l/dm\u00b3"],
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "90%"],
            ["Hydraulic connections size", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "200 l", "300 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Power output", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["u1en", "u2en", "u3en", "u4en", "u5en", "u6en"],
        iconLocations: [[240, 350], [240, 350], [234, 320], [246, 192], [246, 192], [330, 200]],
        description: "A particularly versatile pellet fuel boiler that operates in automatic mode and is designed to burn both good and poor quality pellets, as well as all grain crops, bulk agricultural waste, peat and coal, as well as wood or other solid fuel, which is loaded through the middle door. Inexpensive, reliable and universal pellet fuel boiler, the design of which is specially adapted for burning various fuels. A large horizontal, four-pass heat exchanger ensures convenient cleaning and a high efficiency factor. The boiler is equipped with a „Zenono“ burner.",
        burnerDescription: "Specialized burner capable of burning various grain crops, agricultural waste, low-quality pellets and various size coal in automatic mode. The burner has a stainless steel cleaning mechanism that perfectly removes slag formed during combustion.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/universaDimensions.svg",
        talpa: ['UT 200', 'UT 300'],
        talpaData:[
            ["A1", "mm", 1200, 1300],
            ["B1", "mm", 600, 750]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos UNIVERSA.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        techSpecPdf: "../assets/universa_en.pdf",
        connectionSchemePdf: "../assets/universaPajungimasEN.pdf",
        instructionsPdf: "../assets/universa_instruction.pdf",
    }),
    new Katilas({
        id: 10,
        name: "Pele Max",
        type: "Industrial",
        imageUrl: "assets/pelle_max.png",
        pjuvis: "assets/pelle_max_pjuvis.png",
        feature: "Economical",
        price: 6140,
        pricing:[
            [50, 6140, 460, [0, 100, 300]],
            [70, 7600, 900, [0, 100, 300]],
            [100, 10000, 700, [0, 100, 300]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50,70,100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Wood", "Briquettes", "Coal", "Pellet"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Heated area", "till m\u00b2", 500,700,1000],
            ["Combustion chamber depth", "mm", 680,680,980],
            ["Combustion chamber load", "l/dm\u00b3", 105,145,280],
            ["Rotary pellet burner", "kW", "10-50", "15-70", "20-100"],
            ["Heat exchanger area", "m\u00b2", 6, 7.8, 11.8],
            ["Combustion chamber opening size", "cm", "50x26", "60x26", "70x26"],
            ["Number of horizontal heat exchangers", "units", 5,5,5],
            ["Volume of water in the boiler", "l", 142,180,280],
            ["Weight", "kg", 500,650,890],
            ["Chimney inner diameter", "mm", "185", "185", "200"],
        ],
        additionalData: [
            ["Fuel chamber capacity", "500/700/1000 l/dm\u00b3"],
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "90%"],
            ["Hydraulic connections size", "G 2 colių"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", ">20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "500 l", "700 l", "1000 l"],
            [
                ["Degiklis"],
                ["50", "8-36 kW", "10-50 kW"],
                ["70", "10-50 kW", "15-70 kW"],
                ["100", "15-70 kW", "20-100 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1585, 1585, 1735],
            ["A1", "mm", 1275, 1355, 1430],
            ["B", "mm", 630, 730, 830],
            ["B1", "mm", 650, 750, 850],
            ["C", "mm", 890, 890, 1195],
            ["C1", "mm", 265, 340, 340],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Power output", "36-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pm1en", "pm2en", "pm3en", "pm4en", "pm5en"],
        iconLocations: [[242, 162], [252, 320], [310, 380], [235, 286], [370, 190]],
        description: "A universal pellet boiler for industrial use, with a modern and high-quality rotary burner that can burn even lower-quality pellets. This is a universal boiler that can be used to burn both pellets in fully automatic mode and various solid fuels that are loaded manually. The boiler has one of the largest heat exchanger areas on the market. Five horizontal heat exchangers, many passes and a long smoke path ensure a very high boiler efficiency.",
        burnerDescription: "Fully automatic pellet burner, which was designed to burn good to medium quality pellets. The burner has an automatic cleaning mechanism - a rotating combustion and air supply chamber, which helps the burner to clean itself from ash and slag.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/pele_max_degiklio_instrukcija.pdf",
        dimensionsImage: "../assets/peleMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos PELE MAX.pdf",
        instructionsPdf: "../assets/pele_max_instruction.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        techSpecPdf: "../assets/pele_max_en.pdf",
        connectionSchemePdf: "../assets/peleMaxPajungimasEN.pdf",
    }),
    new Katilas({
        id: 11,
        name: "Agro Max",
        type: ["Agricultural waste", "Industrial"],
        imageUrl: "assets/agro_max.png",
        pjuvis: "assets/agro_max_pjuvis.png",
        feature: "Universal ",
        price: 5700,
        pricing:[
            [50, 5700, 0, [0,200,400]],
            [70, 6900, 0, [0,200,400]],
            [100, 8500, 300, [0,200,400]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A",
        fuelTypes: ["Grains", "Siftings", "Pellet", "Chips", "Coal 0-50", "Wood"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Heated area", "till m\u00b2", 500, 700, 1000],
            ["Combustion chamber depth", "mm", 680, 680, 980],
            ["Combustion chamber load", "l/dm\u00b3", 140, 170, 290],
            ["„Zenono“ burner", "kw", "15-70", "15-70", "20-100"],
            ["Heat exchanger area", "m\u00b2", 5.9, 7.1, 11],
            ["Combustion chamber opening size", "cm", "50x34", "60x34", "70x34"],
            ["Number of horizontal heat exchangers", "units", 5, 5, 5],
            ["Volume of water in the boiler", "l", 145, 165, 280],
            ["Weight", "kg", 550, 650, 930],
            ["Chimney inner diameter", "mm", "185", "185", "200"],
            ["Fuel chamber capacity", "l/dm\u00b3", 400, 600, 800]
        ],
        additionalData: [
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "90%"],
            ["Hydraulic connections size", "G2 inches"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", ">20 Pa"],
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l", "800 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1590, 1590, 1740],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 890, 890, 1190],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Power output", "50-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["am1en", "am2en", "am3en", "am4en", "am5en", "am6en"],
        iconLocations: [[270, 350], [270, 350], [450, 306], [274, 306], [280, 162], [380, 274]],
        description: "It is a particularly versatile boiler for industrial use, designed for automatic burning of various grain crops, agricultural waste, low-quality pellets and various coal. It is a product of the highest category and technical level, specially designed for burning such fuel. A large capacity with a stirring mechanism, a self-cleaning burner, a large ash box and other advantages ensure easy use of the boiler. A large horizontal five-pass heat exchanger ensures convenient cleaning and a high efficiency factor. The boiler is also designed for wood and other solid fuel, which is loaded through the middle door. The boiler is equipped with a „Zenono“ burner.",
        burnerDescription: "Specialized burner capable of burning various grain crops, agricultural waste, low-quality pellets and various size coal in automatic mode. The burner has a stainless steel cleaning mechanism that perfectly removes slag formed during combustion.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroMaxDimensions.svg",
        talpa: ['AT 400', 'AT 600', 'AT 800'],
        talpaData:[
            ["A1", "mm", 1500, 1700, 1700],
            ["B1", "mm", 1080, 1080, 1190]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO MAX.pdf",
        instructionsPdf: "../assets/agro_max_instruction.pdf",
        techSpecPdf: "../assets/agro_max_en.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroMaxPajungimasEN.pdf",
    }),
    new Katilas({
        id: 12,
        name: "Bio Max",
        type: "Industrial",
        imageUrl: "assets/bio_max.png",
        pjuvis: "assets/bio_max_pjuvis.png",
        feature: "Practical",
        price: 3500,
        pricing:[
            [50, 3500, 0, [0]],
            [70, 4500, 0, [0]],
            [100, 5800, 0, [0]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Wood", "Briquettes", "Coal"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Heated area", "till m\u00b2", 500, 700, 1000],
            ["Combustion chamber load", "l/dm\u00b3", 240, 380, 550],
            ["Combustion chamber depth", "mm", 600, 800, 1000], 
            ["Combustion chamber opening size", "cm", "40x50", "40x60", "40x70"],
            ["Heat exchanger area", "m\u00b2", 5, 7.1, 10],
            ["Number of horizontal heat exchangers", "units", 4, 4, 4],
            ["Volume of water in the boiler", "l", 130, 180, 235],
            ["Weight", "kg", 420, 570, 800],
            ["Chimney inner diameter", "mm", "200", "220", "250"],
        ],
        additionalData: [
            ["Hydraulic connections size", "G 2 inches"],
            ["Lowest operating temperature", "60 \u2070C"],
            ["Highest operating temperature", "90 \u2070C"],
            ["Heating efficiency", "83%"],
            ["Highest operating pressure", "1.5 bar"],
            ["Required draft in the chimney", ">20 Pa"],
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1600, 1600, 1600],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 800, 1000, 1200],
        ],
        icons: ["bm1en", "bm2en", "bm3en", "bm4en", "bm5en"],
        iconLocations: [[312, 152], [300, 280], [414, 440], [370, 150], [440, 280]],
        description: "It is a classic construction, reliable and easy-to-use industrial boiler. It also features a very high fuel load, large heat exchanger, long service life and low cost. Boiler fuel capacity and heat exchanger area are among the largest on the market. This ensures a very long burning time, the boiler is very suitable for burning firewood, moist fuel.",
        dimensionsImage: "../assets/bioMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO MAX.pdf",
        techSpecPdf: "../assets/bio_max_en.pdf",
        connectionSchemePdf: "../assets/bioMaxPajungimasEN.pdf",
        instructionsPdf: "../assets/bio_max_instruction.pdf",
    }),
];